<template>
  <div class="related">
    <div class="related-title-container" id="rel-contain" v-if="!changeStyles">
      <p class="related-title-container-title" id="rel-contain-title">{{ $t("Related") }}</p>
    </div>
    <div class="related-component-container">
      <keep-alive v-if="!purgeContent">
        <contentsLisitng
          :listContent="contents"
          :changeStyles="changeStyles"
          :content="content"
          :playerInstance="playerInstance"
          :listType="listType"
          @contentAcquired="handleContentAcquired"
        ></contentsLisitng>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  props: {
    content: {
      type: Object,
    },
    changeStyles: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      contents: null,
      localDisplayLang: null,
      listType: "",
      isMpegRequired: false,
      purgeContent: false,
    };
  },
  watch: {
    content(val) {
      // this.purgeContent = true;
      // this.purgeContent = false;
    },
  },
  computed: {
    fetchCardType() {
      return {
        cardName: "Trailer",
        type: "LANDSCAPE",
        height: "70px",
        playButton: { enablePlay: true, height: "40px", width: "40px" },
      };
    },
  },
  created() {
    console.log("Related carousel created");
    this.isMpegRequired = this.checkMpegRequired();
    this.listType = "Related";

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    // this.getRelatedContents();
  },
  mounted() {
    console.log("Related carousel mounted");
    // eventBus.$on("relatedcontents-response", this.relatedContentList);
  },
  beforeDestroy() {
    console.log("Related carousel destroyed");
  },
  methods: {
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    handleContentAcquired() {
      this.purgeContent = false;
    },
    // getRelatedContents() {
    //   let payload = {
    //     contentid: this.content.objectid,
    //     params: {
    //       displaylanguage: this.localDisplayLang
    //     }
    //   };
    //
    //   if (this.isMpegRequired == true) {
    //       payload.params.mpegtspackage = 'YES';
    //   }
    //   eventBus.$emit("getRelatedcontents", payload);
    // },
    // relatedContentList(payload) {
    //   if (!payload.reason) {
    //     this.contents = payload.data;
    //     this.contents.forEach(element => {
    //       element.isRelated = true;
    //     });
    //     // document.getElementById("vlive_popup").style.overflow = "auto";
    //   } else {
    //     this.contents = [];
    //   }
    // }
  },
  components: {
    contentsLisitng: () =>
      import(/* webpackChunkName: "contentsListing" */ "@/components/Templates/contentsListing.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.related {
  // margin-top: 39px;
  &-title-container {
    padding: 20px;
    background: red;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    background-color: rgba(24, 24, 24, 1);
    &-title {
      opacity: 0.5;
      color: #ffffff;
      font-family: $font-regular;
      font-size: 18px;
      font-weight: bold;
    }
  }
  &-component-container {
    // background-color: rgba(0, 0, 0, 0.8);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &-contents {
    // background-color: rgba(0, 0, 0, 0.8);
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    &-card {
      padding: 20px;
      max-height: 300px;
      overflow-y: scroll;
      &-item {
        padding: 10px 0;
        display: flex;
        &-design {
          width: 100px;
        }
        p {
          margin-left: 10px;
          color: white;
          font-family: $font-regular;
        }
      }
    }
  }
}

// @media only screen and (max-width: 576px) {
//   .related {
//     &-component-container {
//       // background: rgba(0, 0, 0, 0.8);
//     }
//   }
// }

@media only screen and (min-width: 1200px) {
  .related {
    &-title-container {
      padding: 15px 20px;
      &-title {
        font-size: 16px;
      }
    }
  }
}
</style>
